body{
  background-color: #f9f9f9;
  /* font-family: Muli,sans-serif!important; */
  /* font-family: gd-sage,"Times","Times New Roman",serif; */
  /* font-size: 16px!important; */
  font-family: gdsherpa,Helvetica,Arial,sans-serif!important;
  /* font-family: gd-sage,"Times","Times New Roman",serif!important; */

}

/* h1, h2, h3, h4, h5, h6{
    font-family: gd-sage,"Times","Times New Roman",serif!important;
  } */

.p-3.table-responsive{
  background-color: #edf1f4;
}

/*Banner*/
.banner{
  height: auto;
  /* background: rgba(110, 180, 70, 0.5) url("./img/anuncio6.jpg") center top no-repeat; */
  /* background: #6eb4466b  url("./img/anuncio6.jpg") center top no-repeat; */
  background: url("./img/anuncio6.png") center top no-repeat;
  /* background: linear-gradient(180deg, rgba(110, 180, 70, 0.8), rgba(110, 180, 70, 0.8)), url("./img/anuncio6.png") center top no-repeat; */
  /* background-attachment: scroll, scroll; */
  background-size: contain;
  /* background-size: cover; */
  position: relative;
  background-repeat: repeat-y;
  background-color: #156034;
}
.banner-pro{
  height: 100vh;
  /* background: rgba(110, 180, 70, 0.5) url("./img/anuncio6.jpg") center top no-repeat; */
  /* background: #6eb4466b  url("./img/anuncio6.jpg") center top no-repeat; */
  background: url("./img/anuncio6.png") center top no-repeat;
  /* background: linear-gradient(180deg, rgba(110, 180, 70, 0.8), rgba(110, 180, 70, 0.8)), url("./img/anuncio6.png") center top no-repeat; */
  /* background-attachment: scroll, scroll; */
  background-size: contain;
  /* background-size: cover; */
  position: relative;
  background-repeat: repeat-y;
  /* background-color: #1077CB; */
}
/* 
@media (min-width: 768px) {
  .banner{ */
    /* height: 800px; */
  /* }
} */

/* @media (min-width: 1280px) {
  .banner{ */
    /* height: auto; */
  /* }
} */



.banner-2{
  /* background-color: #25705f; */
  background-color:#6eb446;
  padding: 100px 0;
}

.banner-sw{
  background: linear-gradient(180deg, rgba(24, 24, 24, 0.521), #50743b6b), url("https://payments.labeldictate.com/storage/intro-bg.jpg") center top no-repeat;
  /* background: linear-gradient(45deg, rgba(24, 24, 24, 0.521), #74757350), url("https://licencias.test/storage/intro-bg.jpg") center top no-repeat; */
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-size: cover;
  position: relative;
  /* background-color: #74757396; */
}


.banner-encuentra{
  background: url("./img/encuentra.png") center top no-repeat;
  background-attachment: scroll, scroll;
  background-size: auto, auto;
  background-size: cover;
  position: relative;
  height: 400px;
  /* background-color: #74757396; */
}


/*utilidades*/
.box-sh{
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 1);
}

.no-bg-gutter{
  --bs-gutter-x: -1rem;
}

.h-r{
  /* font-size: calc(1.325rem + -0.1vw); */
  font-size: calc(1.325rem + 1.8vw);
}
@media (min-width: 450px) {
  .h-r{
    font-size: calc(1.325rem + .9vw);
  }
  
}

.cursor-ban{
  cursor: not-allowed!important;
}

.justificado{
  text-align: justify;
  text-justify: inter-word;
}

.w-32-pc{
  width: 100%;
}

@media (min-width: 768px) {
  .w-32-pc{
    width: 48%;
  }
}
@media (min-width: 992px) {
  .w-32-pc{
    width: 32%;
  }
}

.h-500px{
  height: 500px;
}
.height-300{
  height: 300px;
}

.mt-25pc{
  margin-top: 25%;
}

.fs--8{
  font-size: .8rem;
}
a.nav-link:hover{
  cursor: pointer;
}

.w-50-100{
  width: 100%;
}
@media (min-width: 992px) {
  .w-50-100{
    width: 50%;
  }
}

.inpute-group{
  width: 100%!important;
}

@media (min-width: 992px) {
  .inpute-group{
    width: 33.3%!important;
  }
  
}

.font-size-5rem{
  font-size: 5rem;
}

.blue-arrow svg{
  color: #BDD0E6;
}

/*widths*/
.h-350{
  height: 350px!important;
}

.h-290{
  height: 290px!important;
}

.w-25-50{
  width: 50%!important;
  cursor: pointer;
}

@media (min-width:720px) {
  .w-25-50{
    width: 25%!important;
  }
  
}

/*Footer*/
body, .sticky-footer-wrapper{
	min-height: 100vh;
}

.flex-fill{
	flex:1 1 auto;
}

.rdt_TableHeadRow div{
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  /* border: 1px solid #373b3e; */
}

.border-radius-10{
  border-radius: 100px;
}

/*Animaciones*/

#anime{
  animation: .7s infinite alternate anime;
}

@keyframes anime {
  from {
    /* transition: ease-in-out all 0.3s; */
    transform: translate(0,0);
  }

  to {
    /* transition: ease-in-out all 0.3s; */
    transform: translate(0,22px);
 }
}

.tra-5{
  opacity: 0.5;
}
.tra-1{
  opacity: 1;
}

/*botones*/
.text-field{
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

  }
  
.text-field:hover{
    cursor: pointer;
}

.clear-button{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card{
  background-color: #ffe4bf94;
  
}

.bg-nowhite{
  width: 100%;
  background-color: #eee0e0ed;
  transition: all ease-in-out .3s;
}

.bg-nowhite:hover{
  box-shadow: 0 0 16px rgba(0,0,0,.3);
}

@media (min-width: 992px) {
  .bg-nowhite:hover{
    transform: scale(1.07);
  }
}

.z-index-1000{
  z-index: 1000;
}

.card-footer{
  background-color: #d4cece;
}

/*List group cliente detalles*/

.col-li-1{
  width: 33.3%;
}

.col-li-2{
  width: 66.6%;
}

@media (max-width: 992px) {
	.col-cel{
		flex-direction: column;
	}

	.col-li-1, .col-li-2{
		width: 100%;
	}
}

.rdt_Table{
  border-radius: 1rem;
}

.bg-gray{
  background-color: rgba(214, 214, 214, 0.75);
}

.cursor-pointer{
  cursor: pointer;
}

.rdt_Pagination{
  border-radius: 0 0 5px 5px;
}

.text-align-res{
  text-align: start;
}

@media (min-width: 768px) {
  .text-align-res{
    text-align: end;
  }
  
}

.jc-res{
  justify-content: center;
}

@media (min-width: 768px) {
  .jc-res{
    justify-content: flex-end;
  }
  
}
.ul {
  list-style: none;
  line-height: 2.5rem;
}
.ul li:before {
  font-weight: bold;
  color: #25705f;
  content: '✓ ';
  font-size: 20px;
}

#tsparticles{
  height: 300px;
  position: relative;
  z-index: 900;
}

#tsparticles canvas{
  position: absolute;
  z-index: 850;
}

.tt{
  position: relative;
}

.tt .textote{
  color: white;
  position: absolute;
  top: 25%;
  left: 35%;
  z-index: 901;
}
.tt .textote2{
  position: absolute;
  /* margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; */ 
  padding-bottom: 1rem;
  text-align: center;
  top: 40%;
  width: 100%;
  margin-bottom: auto;
  margin-top: auto;
  color: red;
  animation: mymove 10s alternate infinite ease-in-out running;
  z-index: 901;
}
.contorno {
  text-shadow:
   0 0 1px rgb(146, 146, 146),
   -1px -1px 0 rgb(146, 146, 146),  
   1px -1px 0 rgb(146, 146, 146),
   -1px 1px 0 rgb(146, 146, 146),
   1px 1px 0 rgb(146, 146, 146);

   /* font-style: italic; */
   font-weight: bold;
 }


@keyframes mymove {
  0% {color: rgb(20, 20, 20);}
  20% {color: #5c5c5c;}
  40% {color: #d8d6d6;}
  60% {color: #d8d6d6;}
  80% {color: #5c5c5c;}
  100% {color: rgb(20, 20, 20)}
}

#easter{
	position: fixed;
	bottom: 50%;
	top: 50%;
	margin: auto auto; /* Will not center vertically and won't work in IE6/7. */
	left: 40%;
	right: 50%;
	font-size: 50px;
	height: 250px;
	width: 330px;
	/* display: none; */
	z-index:10;
  
  -webkit-text-stroke: 0.5px #5c5c5c;
  -moz-text-stroke: 0.5px #5c5c5c;
  -ms-text-stroke: 0.5px #5c5c5c;

	background: linear-gradient(red, blue);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	-moz-background-clip: text;
	-moz-text-fill-color: transparent;

	-ms-background-clip: text;
	-ms-text-fill-color: transparent;
}

@media (max-width: 767px) {
  #easter{
    left: 10%;
  }
}
.mantenimiento{
  position: fixed;
  top: 7%;
  left: 0;
  width: 100%;
  height: 55px;
  border: 1px solid #2427277c;
  background-color: #ffffffce;
  z-index: 9999999;
}

#pagination-next-page, #pagination-previous-page{
  background-color: #5dad2e!important;
  margin: 0px 3px;
}

#pagination-last-page, #pagination-first-page{
  background-color: #247a52!important;
}


/*SISTEMAS OPERATIVOS*/

.body-80{
  height: 70%;
}

.body-20{
  height: 30%;
}

.img-p{
  width: 100px;
  height: auto;
}